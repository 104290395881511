import React from "react";
import { Link } from "react-router-dom";
import InterpretationImage2 from "../assets/images/home1.webp";
import { CheckCircle } from "lucide-react";

const InitialSection: React.FC = () => {
  return (
    <section className="container mx-auto flex flex-col lg:flex-row items-center px-4 space-y-8 lg:space-y-0 lg:space-x-12">
      <div className="text-center lg:text-start lg:w-1/2 space-y-4">
        <h1 className="text-3xl font-extrabold leading-tight tracking-tight text-gray-900 lg:text-5xl">
          Professional Interpretation Services for{" "}
          <span style={{ color: '#025f99' }}>Every Language and Industry</span>
        </h1>
        <p className="text-lg text-gray-500">
          Our network of professional interpreters ensures accurate
          communication across languages. Connect with experts who specialize in
          legal, medical, and business fields, available anytime, anywhere.
        </p>

        <div className="flex flex-wrap justify-center lg:justify-start lg:-ml-4 gap-2 mt-4">
          {[
            {
              text: "Confidentiality and Ethics",
              icon: <CheckCircle className="h-5 w-5 text-blue-500" />,
            },
            {
              text: "Certified interpreters for official use",
              icon: <CheckCircle className="h-5 w-5 text-green-500" />,
            },
            {
              text: "Specialized in urgent and scheduled services",
              icon: <CheckCircle className="h-5 w-5 text-purple-500" />,
            },
            {
              text: "Support available 24/7 worldwide",
              icon: <CheckCircle className="h-5 w-5 text-yellow-500" />,
            },
          ].map((benefit, index) => (
            <div
              key={index}
              className="flex items-center space-x-2 text-gray-700 bg-gray-100 px-3 py-2 rounded-md"
            >
              {benefit.icon}
              <span className="text-sm font-medium">{benefit.text}</span>
            </div>
          ))}
        </div>

        <div className="flex justify-center lg:justify-start space-x-4 mt-8">
          <Link
            to="/free-quote"
            className="bg-[#025f99] text-white px-6 py-3 rounded-lg text-lg hover:bg-[#014a73]"
          >
            Get a Quote Now
          </Link>
        </div>
      </div>

      <div className="w-full lg:w-1/3 lg:w-1/2 flex justify-center">
        <img
          src={InterpretationImage2}
          alt="Interpretation illustration"
          className="max-w-full lg:max-w-md rounded-lg object-cover animate-slide-in lg:ml-10 mb-4"
          loading="lazy"
        />
      </div>
    </section>
  );
};

export default InitialSection;
