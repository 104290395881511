import React from "react";
import TestimonialsSection from "../../../components/TestimonialsSection";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import ContentSection from "../../../components/ContentSection";
import PricingPlans from "../../../components/PricingPlans";
import QuoteForm from "../../../components/QuoteForm";
import { FaComments, FaHeadphones, FaMicroscope, FaVideo } from "react-icons/fa6";
import { FaGlobe, FaCheck, FaCertificate, FaIndustry } from "react-icons/fa";
import Helmet from "react-helmet";
// images
import PeopleImage from "../../../../src/assets/images/adobe/Legal1.webp";
import NYCImage1 from "../../../../src/assets/images/adobe/NYC.webp";
import LandingBanner from "../../../components/LandingBanner";
import ClientsCarousel from "../../../components/ClientsCarousel";
import SubFooter from "../../../components/SubFooter";

const LandingOSINYC: React.FC = () => {
  const pricingPlans = [
    {
      icon: FaComments,
      title: "Consecutive",
      price: 99,
      description: "Professional consecutive interpretation for formal events.",
      benefits: [
        "Clear and precise communication",
        "Smooth small-group communication",
        "Strict confidentiality agreements",
      ],
      url: "#quote-form",
    },
    {
      icon: FaMicroscope,
      title: "Simultaneous",
      price: 99,
      description: "Real-time interpretation for live conferences.",
      benefits: [
        "Clear interpretation for large events",
        "Instant interpretation for live events",
        "Real-time accuracy for global meetings",
      ],
      url: "#quote-form",
    },
    {
      icon: FaHeadphones,
      title: "Equipment",
      price: 99,
      description: "Rental equipment for interpretation needs.",
      benefits: [
        "Wireless Headphones and Headsets",
        "Soundproof Booths",
        "Interpreter Consoles",
        "Tour-Guide Systems",
        "Transmitters and Receivers",
      ],
      url: "#quote-form",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services New York City</title>
        <meta
          name="description"
          content="Trusted Interpreters offers certified interpretation services in New York for all industries. Call us today!"
        />
      </Helmet>
      <PageHeader
        title="On-Site Interpretation Services in New York"
        description="Trusted Interpreters provides high-quality on-site interpretation services in New York, specializing in both consecutive and simultaneous interpretation. Our expert linguists, combined with advanced interpreting technology, ensure seamless communication in any setting. Whether you need interpretation for business meetings, medical appointments, legal proceedings, or large conferences in New York, we deliver precise and professional services tailored to your needs."
        imageUrl={NYCImage1}
      />
      <div className="container mx-auto px-8 pt-6">
        <ClientsCarousel/>
      </div>
      <div className="container mx-auto px-4 py-16">
      <div className="flex flex-col lg:flex-row w-full">
          <div className="w-80 py-12 mx-auto">
          <h2 className="text-2xl font-bold mb-4 mt-2">Leading Interpretation Services Company</h2>
          <ul className="pl-0 text-gray-600 gap-3 flex flex-col">
            <li className="flex items-start gap-3">
              <FaGlobe className="text-[#025F99] mt-1 w-[40px]" />
              <span>Trusted by over 10,000 businesses worldwide</span>
            </li>
            <li className="flex items-start gap-3">
              <FaCheck className="text-[#025F99] mt-1 w-[40px]" />
              <span>Backed by 20+ years of industry experience</span>
            </li>
            <li className="flex items-start gap-3">
              <FaVideo className="text-[#025F99] mt-1 w-[40px]" />
              <span>Professional Video Remote Interpretation (VRI) services</span>
            </li>
            <li className="flex items-start gap-3">
              <FaCertificate className="text-[#025F99] mt-1 w-[40px]" />
              <span>Certified interpreters for accurate and reliable communication</span>
            </li>
            <li className="flex items-start gap-3">
              <FaIndustry className="text-[#025F99] mt-1 w-[40px]" />
              <span>Available on-demand across multiple industries and settings</span>
            </li>
          </ul>
          
          </div>
          <div id="quote-form" className="w-full">
            <QuoteForm />
          </div>
        </div>

        <PricingPlans
          title="On-Site Interpretation Services starting from $99"
          plans={pricingPlans}
        />

        <ContentSection
          title="On-Site Interpreters in New York"
          content="Our on-site interpreters in New York undergo extensive training to meet the highest industry standards. With expertise in over 200 languages and dialects, our professionals are well-versed in various industries, ensuring accurate and culturally appropriate interpretations. No matter the complexity of your subject matter, Trusted Interpreters guarantees the right language expert for the job."
          contentLinks={[
            { linkText: "200 languages and dialects", link: "/language-expertise/" },
            { linkText: "various industries", link: "/expertise/" },
          ]}                    
          imageUrl={PeopleImage}
          direction="right"
        />
      </div>

      <LandingBanner />

      {/* testimonials */}
      <TestimonialsSection />

      <SubFooter />
    </div>
  );
};

export default LandingOSINYC;
