import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import client from '../../services/contentfulClient';
import Helmet from "react-helmet";

const Blog: React.FC = () => {
  const [posts, setPosts] = useState<any[]>([]);

  useEffect(() => {
    client
      .getEntries({ content_type: 'blogPost' })
      .then((response) => setPosts(response.items))
      .catch(console.error);
  }, []);

  return (
    <div className="container mx-auto px-4 py-4">
      <Helmet>
        <title>Trusted Interpreters Blog</title>
        <meta
          name="description"
          content="Discover industry updates and professional tips about interpretation services."
        />
      </Helmet>
      <h1 className="text-4xl font-bold text-center">Blog</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
        {posts.map((post) => (
          <div key={post.sys.id} className="bg-white shadow-lg rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-2">{post.fields.title}</h2>
            <p className="text-gray-600">
              {post.fields.body?.content[0]?.content[0]?.value.substring(0, 200)}...
              <Link to={`/blog/${post.fields.slug}`} className="text-blue-600 hover:underline mt-4 block">
                Read more
              </Link>
            </p>
            {post.fields.media?.fields?.file?.url && (
              <div className="flex justify-center mt-6">
                <img
                  src={post.fields.media.fields.file.url}
                  alt={post.fields.title}
                  className="max-w-full max-h-96 rounded-lg shadow-md"
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blog;
