import React, { useEffect } from "react";
import LandingInfo from "../../components/LandingInfo";
import ClientsCarousel from "../../components/ClientsCarousel";
import OurProcess from "../../components/OurProcess";
import InitialSection from "../../components/InitialSection";
import FAQ from "../../components/FAQ";
import TestimonialsSection from "../../components/TestimonialsSection";
import Footer from "../../components/Footer";
import PricingPlans from "../../components/PricingPlans";
import HighlightedSection from "../../components/HighlightedSection";
import KeyBenefits from "../../components/KeyBenefits";
import ContentSection from "../../components/ContentSection";
import InteractiveMap from "../../components/InteractiveMap";
import CallToAction from "../../components/CallToAction";
import HomeOrganizationSchema from "../../components/HomeOrganizationSchema";
import {
  FaCertificate,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaUserShield,
  FaVideo,
  FaWrench,
} from "react-icons/fa";
// images
import OnsiteImage2 from "../../../src/assets/images/adobe/onsite-img-2.webp";

const Home: React.FC = () => {
  useEffect(() => {
    localStorage.setItem("previousPage", "home");
  }, []);

  const pricingPlans = [
    {
      icon: FaVideo,
      title: "Video Remote",
      price: 56,
      description: "For large organizations and enterprises",
      benefits: [
        "Real-time remote interpretation",
        "Combines audio and visual clarity",
        "Accessible anywhere online",
        "Wide Availability of Interpreters",
        "Professional Privacy and Security",
      ],
      url: "https://orders.trustedinterpreters.com/iform-quote/KYLPv11681413693?service_type=interpretation&text=remote&landing=2&website_sale_c=www.trustedinterpreters.com",
    },
    {
      icon: FaMapMarkerAlt,
      title: "On-Site",
      price: 99,
      description: "Ideal for individuals and small teams",
      benefits: [
        "Interpretation Quality Assured",
        "Industry-specific expertise",
        "Nationwide availability",
        "Wide Availability of Interpreters",
        "Professional Privacy and Security",
      ],
      url: "https://orders.trustedinterpreters.com/iform-quote/KYLPv11681413693?service_type=interpretation&text=onsite&type_i=onsite_consecutive&landing=2&website_sale_c=www.trustedinterpreters.com",
    },
    {
      icon: FaPhoneAlt,
      title: "Over-The-Phone",
      price: 1.70,
      description: "Perfect for growing businesses",
      benefits: [
        "Fast and reliable interpretation",
        "Available 24/7 on demand",
        "Covers 200+ languages",
        "Wide Availability of Interpreters",
        "Professional Privacy and Security",
      ],
      url: "https://orders.trustedinterpreters.com/iform-quote/KYLPv11681413693?service_type=interpretation&text=overphone&landing=2&website_sale_c=www.trustedinterpreters.com",
    },
  ];

  const benefits = [
    {
      icon: FaCertificate,
      title: "Certified Professionals",
      text: "Our interpreters are trained to ensure accuracy and cultural competence.",
    },
    {
      icon: FaUserShield,
      title: "Professional Privacy and Security",
      text: "Maintaining confidentiality with secure and professional interpretations.",
    },
    {
      icon: FaWrench,
      title: "Customized Solutions",
      text: "Flexible options tailored to fit your unique project needs.",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-200 min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <InitialSection />

        <ClientsCarousel />

        <PricingPlans
          title="Interpretation Services starting from $56"
          plans={pricingPlans}
        />

        <TestimonialsSection />

        <HighlightedSection title="Why Choose Trusted Interpreters?">
          <KeyBenefits benefits={benefits} />
        </HighlightedSection>

        <LandingInfo />

        <OurProcess />

        <div className="pt-8">
          <ContentSection
            title="Any Language, Any Place, Any Time"
            content="At Trusted Interpreters, we are dedicated to making communication accessible across borders. From urgent requests to long-term projects, we are here to provide reliable, high-quality interpretation services that suit your requirements.
                  With 24/7 availability and a global network of professionals, we ensure you’ll never face language barriers again."
            imageUrl={OnsiteImage2}
            direction="left"
          />
        </div>

        {/* <div className="container mx-auto px-4 py-6">
          <InteractiveMap />
        </div> */}

        <div className="pt-12">
          <CallToAction
            title="Start Your Interpretation Journey Today"
            description="Break down communication barriers with Trusted Interpreters. Whether you need on-site services for an upcoming event, video interpretation for virtual meetings, or industry-specific expertise, we are here to help. Contact us today to explore our services and receive a personalized quote tailored to your project."
            buttonText="Get a Quote"
            buttonLink="/free-quote"
          />
        </div>

        <FAQ />

      </div>
      <Footer />
      <HomeOrganizationSchema />
    </div>
  );
};

export default Home;
