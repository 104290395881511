import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import { ChevronDown, ChevronUp, Phone } from "lucide-react";
import { HiMenu, HiX } from "react-icons/hi";
import { HiDocumentText } from "react-icons/hi";
import Cookies from 'js-cookie';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import ScrollToTop from "./components/ScrollToTop";
import SchemaMarkup from "./components/Schema";
// menu header
import OnSiteMenu from "./components/OnSiteMenu";
import OverThePhoneMenu from "./components/OverThePhoneMenu";
import VRIMenu from "./components/VRIMenu";
import SignLanguageMenu from "./components/SignLanguageMenu";
import AboutUsMenu from "./components/AboutUsMenu";
import Home from "./pages/home";
import Quote from "./pages/quote/Quote";
import Thanks from "./components/Thanks";
import ThanksLanding from "./components/ThanksLanding";

// images
import LogoImage from "./assets/images/logo_ti.jpg";

// on-site pages
import OnSite from "./pages/interpretation-types/on-site/on-site";
import Consecutive from "./pages/interpretation-types/on-site/consecutive";
import Simultaneous from "./pages/interpretation-types/on-site/simultaneous";
import Equipment from "./pages/interpretation-types/on-site/equipment";

// over the phone pages
import OverThePhone from "./pages/interpretation-types/phone-interpretation/Over-The-Phone";
import OneTime from "./pages/interpretation-types/phone-interpretation/One-Time-Service";
import MonthlyRecurrent from "./pages/interpretation-types/phone-interpretation/Monthly-Recurrent-Service";

// vri pages
import VRI from "./pages/interpretation-types/vri/VRI";
import Zoom from "./pages/interpretation-types/vri/Zoom";
import GoogleMeet from "./pages/interpretation-types/vri/Google-Meet";
import Teams from "./pages/interpretation-types/vri/Teams";
import Skype from "./pages/interpretation-types/vri/Skype";
import Others from "./pages/interpretation-types/vri/Others";

// sign language pages
import HomeASL from "./pages/interpretation-types/asl-interpretation/ASL";
import ASL from "./pages/interpretation-types/asl-interpretation/American-Sign-Language";
import ESL from "./pages/interpretation-types/asl-interpretation/English-Sign-Language";
import RID from "./pages/interpretation-types/asl-interpretation/RID-Certified-ASL-Interpreters";
import ConsecutiveSimultaneous from "./pages/interpretation-types/consecutive-simultaneous";

// about us
import AboutUs from "./pages/about-us";
import TC from "./components/TermsAndConditions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Blog from "./pages/blog/Blog";
import PostPage from "./pages/blog/PostPage";
import Interpreters from "./pages/about-us/our-interpreters/Interpreters";
import Certifications from "./pages/about-us/certifications/Certifications";

// languages pages
import LanguageExpertise from "./pages/language-expertise/Language-Expertise";
import SpanishExpertise from "./pages/language-expertise/Spanish-Interpretation";
import EnglishExpertise from "./pages/language-expertise/English-Interpretation";
import ChineseExpertise from "./pages/language-expertise/Chinese-Interpretation";
import FrenchExpertise from "./pages/language-expertise/French-Interpretation";
import JapaneseExpertise from "./pages/language-expertise/Japanese-Interpretation";
import ArabicExpertise from "./pages/language-expertise/Arabic-Interpretation";
import GermanExpertise from "./pages/language-expertise/German-Interpretation";
import PortugueseExpertise from "./pages/language-expertise/Portuguese-Interpretation";
import ItalianExpertise from "./pages/language-expertise/Italian-Interpretation";
import HaitianExpertise from "./pages/language-expertise/Haitian-Interpretation";

// industries pages
import Expertise from "./pages/expertise";
import LegalInterpretation from "./pages/expertise/legal-interpretation/Legal-Interpretation";
import MedicalInterpretation from "./pages/expertise/medical-interpretation/Medical-Interpretation";
import ImmigrationInterpretation from "./pages/expertise/immigration-interpretation/Immigration-Interpretation";
import BusinessInterpretation from "./pages/expertise/business-interpretation/Business-Interpretation";
import FinancialInterpretation from "./pages/expertise/financial-interpretation/Financial-Interpretation";
import TechnologyInterpretation from "./pages/expertise/technology-interpretation/Technology-Interpretation";
import EducationalInterpretation from "./pages/expertise/educational-interpretation/Educational-Interpretation";
import GovernmentInterpretation from "./pages/expertise/government-interpretation/Government-Interpretation";

// cities
import Austin from "./pages/interpretation-services/austin";
import Miami from "./pages/interpretation-services/miami";
import Boston from "./pages/interpretation-services/boston";
import Charlotte from "./pages/interpretation-services/charlotte";
import Chicago from "./pages/interpretation-services/chicago";
import Columbus from "./pages/interpretation-services/columbus";
import Dallas from "./pages/interpretation-services/dallas";
import Denver from "./pages/interpretation-services/denver";
import ElPaso from "./pages/interpretation-services/el-paso";
import FortWorth from "./pages/interpretation-services/fort-worth";
import Houston from "./pages/interpretation-services/houston";
import Indianapolis from "./pages/interpretation-services/indianapolis";
import Jacksonville from "./pages/interpretation-services/jacksonville";
import LosAngeles from "./pages/interpretation-services/los-angeles";
import Nashville from "./pages/interpretation-services/nashville";
import NewYork from "./pages/interpretation-services/new-york";
import OklahomaCity from "./pages/interpretation-services/oklahoma-city";
import Philadelphia from "./pages/interpretation-services/philadelphia";
import Phoenix from "./pages/interpretation-services/phoenix";
import Portland from "./pages/interpretation-services/portland";
import SanAntonio from "./pages/interpretation-services/san-antonio";
import SanDiego from "./pages/interpretation-services/san-diego";
import SanFrancisco from "./pages/interpretation-services/san-francisco";
import SanJose from "./pages/interpretation-services/san-jose";
import Seattle from "./pages/interpretation-services/seattle";
import Washington from "./pages/interpretation-services/washington";

// 404 not found
import NotFound from "./components/NotFound";

// landings
import LandingOSIMiami from "./pages/landings/on-site/LandingOnSiteMiami";
import LandingOSINYC from "./pages/landings/on-site/LandingOnSiteNewYork";
import LandingOSIWashington from "./pages/landings/on-site/LandingOnSiteWashington";
import LandingOSIChicago from "./pages/landings/on-site/LandingOnSiteChicago";
import LandingVRI from "./pages/landings/vri/LandingVRI";
import LandingNYC from "./pages/landings/cities/LandingNewYork";
import LandingChicago from "./pages/landings/cities/LandingChicago";

declare global {
  interface Window {
    uetq: any[];
  }
}

function uet_report_conversion() {
  window.uetq = window.uetq || [];
  window.uetq.push('event', 'contact', {
    event_label: 'click_phone_miami',
  });
}

const App: React.FC = () => {
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const closeTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isLanding = window.location.pathname.includes("/landing/");

  const handleQuoteRedirect = () => {
    const target = document.getElementById("quote-form");
  
    if (target) {
      const targetPosition = target.getBoundingClientRect().top + window.scrollY;

      const headerHeight = 50; 

      window.scrollTo({ top: targetPosition - headerHeight, behavior: "smooth" });
    } else {
      localStorage.setItem("scrollToQuoteForm", "true");
      window.location.href = "/free-quote";
    }
  };
  

  const handleMouseEnter = (menu: string) => {
    if (closeTimeout.current) {
      clearTimeout(closeTimeout.current);
    }
    setActiveMenu(menu);
  };

  const handleMouseLeave = () => {
    closeTimeout.current = setTimeout(() => {
      setActiveMenu(null);
    }, 200);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };
    useEffect(() => {
      const loadCallRail = () => {
        const script = document.createElement("script")
        script.src = "//cdn.callrail.com/companies/264620413/5a552952aa7fdafcc9fe/12/swap.js"
        script.async = true
        script.onload = () => {
          
          setTimeout(() => {
            const phoneLinks = document.querySelectorAll('a[data-phone-number="true"]')
            phoneLinks.forEach((phoneLink) => {
              const currentHref = phoneLink.getAttribute("href")
              if (currentHref && currentHref.startsWith("tel:+")) {
  
                const correctedHref = correctPhoneNumber(currentHref)
                if (correctedHref !== currentHref) {
                  phoneLink.setAttribute("href", correctedHref)
  
                  const correctedNumberText = formatPhoneNumberForDisplay(correctedHref)
                  phoneLink.textContent = correctedNumberText
                }
              }
            })
          }, 2000)
        }
        document.body.appendChild(script)
      }
      
      const correctPhoneNumber = (phoneNumber: string) => {
  
        const cleanedNumber = phoneNumber.replace(/\D/g, "");
  
        if (cleanedNumber.length === 11 && !cleanedNumber.startsWith("1")) {
          const correctedNumber = `tel:+1${cleanedNumber.slice(0, -1)}`;
          return correctedNumber;
        } else if (cleanedNumber.length === 10) {
          const correctedNumber = `tel:+1${cleanedNumber}`;
          return correctedNumber;
        } else if (cleanedNumber.length === 12 && cleanedNumber.startsWith("1")) {
          const correctedNumber = `tel:+${cleanedNumber}`;
          return correctedNumber;
        }
      
        return phoneNumber;
      }
      
      const formatPhoneNumberForDisplay = (phoneNumber: string) => {
        const cleanedNumber = phoneNumber.replace(/\D/g, "");
        if (cleanedNumber.length === 11) {
          return `+1 (${cleanedNumber.slice(1, 4)}) ${cleanedNumber.slice(4, 7)}-${cleanedNumber.slice(7)}`;
        } else if (cleanedNumber.length === 12) {
          return `+${cleanedNumber.slice(0, 2)} (${cleanedNumber.slice(2, 5)}) ${cleanedNumber.slice(5, 8)}-${cleanedNumber.slice(8)}`;
        }
        return phoneNumber;
      }
      loadCallRail()
              const params = new URLSearchParams(window.location.search);
              const utmSource = params.get("utm_source") || "other_online";
              const referrer = document.referrer;
              const gclid = params.get("gclid");
              const userAgent = navigator.userAgent;
              let leadSource = '';
              const msnId = params.get("msnid");
              
              if (referrer.includes("google")){
                leadSource = 'google_organic';
              } else if (referrer.includes("yahoo")){
                leadSource = 'yahoo_organic';
              } else if (referrer.includes("bing")){
                leadSource = 'bing_organic';
              } else{
                leadSource = 'other_online';
              }
              
              if (utmSource == 'google_adwords'){
                leadSource = utmSource;
              }
              if (utmSource == 'meta'){
                leadSource = 'meta_ads';
              }
              if (utmSource == 'gmb'){
                leadSource = 'gmb';
              }
              if (utmSource == 'bingplaces'){
                leadSource = 'bingplaces';
              }
              if (msnId !== null && msnId !== ''){
                leadSource = 'msn_ads';
              }
              if (leadSource != '') {
                Cookies.set('lead_source', leadSource, { expires: 30 });
                if (gclid !== null && gclid !== ''){
                  Cookies.set('gclid', gclid, { expires: 30 });
                }
                if (msnId !== null && msnId !== ''){
                  Cookies.set('msnid', msnId, { expires: 30 });
                }
              }
              console.log("User-Agent:", userAgent); // Verifica que tenga un valor
              if (userAgent) {
                Cookies.set('seo_user_agent_c', userAgent, { expires: 30, sameSite: 'Lax' });
              }

              const isMiamiLanding = window.location.pathname === "/landing/on-site-interpretation-services-miami";

              if (isMiamiLanding) {
                const phoneLink = document.querySelector('a[data-phone-number="true"]');
                
                if (phoneLink) {
                  const handleClick = () => {
                    uet_report_conversion();
                  };
            
                  phoneLink.addEventListener("click", handleClick);
            
                  // Limpieza del event listener cuando el componente se desmonta o cambia de página
                  return () => {
                    phoneLink.removeEventListener("click", handleClick);
                  };
                }
              }
    }, [])

  return (
    <Router>
      <ScrollToTop />
      <LocationHandler
        setIsMobileMenuOpen={setIsMobileMenuOpen}
        setActiveMenu={setActiveMenu}
      />
      <div className="min-h-screen flex flex-col bg-gray-50">
        <Helmet>
          <title>Trusted Interpreters - Breaking Language Barriers</title>
          <meta
            name="description"
            content="Discover Trusted Interpreters, providing expert interpretation services for industries worldwide."
          />
        </Helmet>
        <SchemaMarkup />
        <header className="bg-white shadow-sm sticky top-0 z-50">
          <nav className="container mx-auto py-3 flex justify-between items-center">
            {/* Logo - En landings NO es cliqueable */}
            {isLanding ? (
              <img
                src={LogoImage}
                alt="Trusted Interpreters Logo"
                className="h-12 lg:h-16 w-auto"
              />
            ) : (
              <Link to="/">
                <img
                  src={LogoImage}
                  alt="Trusted Interpreters Logo"
                  className="h-12 lg:h-16 w-auto"
                />
              </Link>
            )}

            {/* Hamburger Button for Mobile & Tablets */}
            {!isLanding && (
              <>
                <button
                  onClick={toggleMobileMenu}
                  className="lg:hidden text-gray-600 hover:text-blue-600"
                >
                  {isMobileMenuOpen ? (
                    <HiX className="h-6 w-6" />
                  ) : (
                    <HiMenu className="h-6 w-6" />
                  )}
                </button>

                {/* Navigation */}
                <ul
                  className={`${
                    isMobileMenuOpen ? "flex" : "hidden"
                  } flex-col lg:flex lg:flex-row lg:space-x-4 gap-3 lg:gap-0 pb-4 lg:pb-0 absolute lg:static top-full left-0 w-full lg:w-auto bg-white lg:bg-transparent shadow-md lg:shadow-none z-40 lg:ml-2`}
                >
                  {/* On-Site */}
                  <li
                    onMouseEnter={() =>
                      !isMobileMenuOpen && handleMouseEnter("OnSite")
                    }
                    onMouseLeave={() => !isMobileMenuOpen && handleMouseLeave()}
                    className="relative px-6 lg:px-0"
                  >
                    <Link
                      to="/interpretation-types/on-site"
                      className="text-gray-600 hover:text-blue-600 flex items-center space-x-1 w-full text-left"
                      onClick={(e) => {
                        if (window.innerWidth < 1024) {
                          e.preventDefault();
                          if (activeMenu === "OnSite") {
                            window.location.href =
                              "/interpretation-types/on-site";
                          } else {
                            setActiveMenu("OnSite");
                          }
                        } else {
                          handleMouseEnter("OnSite");
                        }
                      }}
                    >
                      <span className="text-lg lg:text-base whitespace-nowrap">On-Site</span>
                      {activeMenu === "OnSite" ? (
                        <ChevronUp className="h-4 w-4 text-gray-600" />
                      ) : (
                        <ChevronDown className="h-4 w-4 text-gray-600" />
                      )}
                    </Link>
                    {activeMenu === "OnSite" && (
                      <OnSiteMenu onMouseLeave={handleMouseLeave} />
                    )}
                  </li>

                  {/* Over-The-Phone */}
                  <li
                    onMouseEnter={() =>
                      !isMobileMenuOpen && handleMouseEnter("OverThePhone")
                    }
                    onMouseLeave={() => !isMobileMenuOpen && handleMouseLeave()}
                    className="relative px-6 lg:px-0"
                  >
                    <Link
                      to="/interpretation-types/phone-interpretation"
                      className="text-gray-600 hover:text-blue-600 flex items-center space-x-1 w-full text-left"
                      onClick={(e) => {
                        if (window.innerWidth < 1024) {
                          e.preventDefault();
                          if (activeMenu === "OverThePhone") {
                            window.location.href =
                              "/interpretation-types/phone-interpretation";
                          } else {
                            setActiveMenu("OverThePhone");
                          }
                        } else {
                          handleMouseEnter("OverThePhone");
                        }
                      }}
                    >
                      <span className="text-lg lg:text-base whitespace-nowrap">
                        Over-The-Phone
                      </span>
                      {activeMenu === "OverThePhone" ? (
                        <ChevronUp className="h-4 w-4 text-gray-600" />
                      ) : (
                        <ChevronDown className="h-4 w-4 text-gray-600" />
                      )}
                    </Link>
                    {activeMenu === "OverThePhone" && (
                      <OverThePhoneMenu onMouseLeave={handleMouseLeave} />
                    )}
                  </li>

                  {/* VRI */}
                  <li
                    onMouseEnter={() =>
                      !isMobileMenuOpen && handleMouseEnter("VRI")
                    }
                    onMouseLeave={() => !isMobileMenuOpen && handleMouseLeave()}
                    className="relative px-6 lg:px-0"
                  >
                    <Link
                      to="/interpretation-types/vri"
                      className="text-gray-600 hover:text-blue-600 flex items-center space-x-1 w-full text-left"
                      onClick={(e) => {
                        if (window.innerWidth < 1024) {
                          e.preventDefault();
                          if (activeMenu === "VRI") {
                            window.location.href = "/interpretation-types/vri";
                          } else {
                            setActiveMenu("VRI");
                          }
                        } else {
                          handleMouseEnter("VRI");
                        }
                      }}
                    >
                      <span className="text-lg lg:text-base whitespace-nowrap">VRI</span>
                      {activeMenu === "VRI" ? (
                        <ChevronUp className="h-4 w-4 text-gray-600" />
                      ) : (
                        <ChevronDown className="h-4 w-4 text-gray-600" />
                      )}
                    </Link>
                    {activeMenu === "VRI" && (
                      <VRIMenu onMouseLeave={handleMouseLeave} />
                    )}
                  </li>

                  {/* Sign Language */}
                  <li
                    onMouseEnter={() =>
                      !isMobileMenuOpen && handleMouseEnter("SignLanguage")
                    }
                    onMouseLeave={() => !isMobileMenuOpen && handleMouseLeave()}
                    className="relative px-6 lg:px-0"
                  >
                    <Link
                      to="/interpretation-types/asl-interpretation"
                      className="text-gray-600 hover:text-blue-600 flex items-center space-x-1 w-full text-left"
                      onClick={(e) => {
                        if (window.innerWidth < 1024) {
                          e.preventDefault();
                          if (activeMenu === "SignLanguage") {
                            window.location.href =
                              "/interpretation-types/asl-interpretation";
                          } else {
                            setActiveMenu("SignLanguage");
                          }
                        } else {
                          handleMouseEnter("SignLanguage");
                        }
                      }}
                    >
                      <span className="text-lg lg:text-base whitespace-nowrap">
                        Sign Language
                      </span>
                      {activeMenu === "SignLanguage" ? (
                        <ChevronUp className="h-4 w-4 text-gray-600" />
                      ) : (
                        <ChevronDown className="h-4 w-4 text-gray-600" />
                      )}
                    </Link>
                    {activeMenu === "SignLanguage" && (
                      <SignLanguageMenu onMouseLeave={handleMouseLeave} />
                    )}
                  </li>

                  {/* About Us */}
                  <li
                    onMouseEnter={() =>
                      !isMobileMenuOpen && handleMouseEnter("AboutUs")
                    }
                    onMouseLeave={() => !isMobileMenuOpen && handleMouseLeave()}
                    className="relative px-6 lg:px-0"
                  >
                    <Link
                      to="/about-us"
                      className="text-gray-600 hover:text-blue-600 flex items-center space-x-1 w-full text-left"
                      onClick={(e) => {
                        if (window.innerWidth < 1024) {
                          e.preventDefault();
                          if (activeMenu === "AboutUs") {
                            window.location.href = "/about-us";
                          } else {
                            setActiveMenu("AboutUs");
                          }
                        } else {
                          handleMouseEnter("AboutUs");
                        }
                      }}
                    >
                      <span className="text-lg lg:text-base whitespace-nowrap">About Us</span>
                      {activeMenu === "AboutUs" ? (
                        <ChevronUp className="h-4 w-4 text-gray-600" />
                      ) : (
                        <ChevronDown className="h-4 w-4 text-gray-600" />
                      )}
                    </Link>
                    {activeMenu === "AboutUs" && (
                      <AboutUsMenu onMouseLeave={handleMouseLeave} />
                    )}
                  </li>

                  {/* Blog */}
                  <li>
                    <Link
                      to="/blog"
                      className="text-lg lg:text-base text-gray-600 hover:text-blue-600 px-6 lg:px-0 whitespace-nowrap mr-1"
                    >
                      Blog
                    </Link>
                  </li>

                  {/* Request a Quote (Mobile only) */}
                  <li className="block lg:hidden mt-4 px-4 text-center">
                    <button
                      onClick={handleQuoteRedirect}
                      className="bg-[#025f99] text-white px-4 py-2 rounded-lg text-center block hover:bg-[#014a73] w-full"
                    >
                      Request a Free Quote
                    </button>
                    <a
                      href="tel:+18884442554"
                      className="mt-2 bg-[#025f99] text-white px-4 py-2 rounded-lg text-center block hover:bg-[#014a73] w-full"
                      data-phone-number="true"
                      data-number="1 (888) 444-2554"
                    >
                      1 (888) 444-2554
                    </a>
                  </li>
                </ul>
              </>
            )}
            
            {/* Request a Quote Button */}
            <div className="flex flex-row text-center lg:pl-12">
              <div className="hidden lg:flex bg-[#025f99] text-white px-2 py-1 mr-2 mb-1 rounded hover:bg-[#014a73] items-center whitespace-nowrap"><Phone className="mr-1" size={16}/>
                <a
                    href="tel:+18884442554"
                    data-phone-number="true"
                    data-number="1 (888) 444-2554"
                  >
                    1 (888) 444-2554
                </a>
              </div>
              <button
                onClick={handleQuoteRedirect}
                className="hidden lg:flex bg-[#025f99] text-white px-2 py-1 mb-1 rounded hover:bg-[#014a73] items-center whitespace-nowrap"
              >
                <HiDocumentText className="mr-1" size={16} />
                Request a Free Quote
              </button>
            </div>
          </nav>
        </header>
        
        {/* Routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          {/* interpretation types */}
          {/* on-site route*/}
          <Route path="/interpretation-types/on-site" element={<OnSite />} />
          <Route
            path="/interpretation-types/on-site/consecutive"
            element={<Consecutive />}
          />
          <Route
            path="/interpretation-types/on-site/simultaneous"
            element={<Simultaneous />}
          />
          <Route
            path="/interpretation-types/on-site/equipment"
            element={<Equipment />}
          />

          {/* over the phone route */}
          <Route
            path="/interpretation-types/phone-interpretation"
            element={<OverThePhone />}
          />
          <Route
            path="/interpretation-types/phone-interpretation/one-time-service"
            element={<OneTime />}
          />
          <Route
            path="/interpretation-types/phone-interpretation/monthly-recurrent-service"
            element={<MonthlyRecurrent />}
          />

          {/* vri route */}
          <Route path="/interpretation-types/vri" element={<VRI />} />
          <Route
            path="/interpretation-types/vri/zoom-interpretation-services"
            element={<Zoom />}
          />
          <Route
            path="/interpretation-types/vri/google-meet-interpretation-services"
            element={<GoogleMeet />}
          />
          <Route
            path="/interpretation-types/vri/microsoft-teams-interpretation-services"
            element={<Teams />}
          />
          <Route
            path="/interpretation-types/vri/skype-interpretation-services"
            element={<Skype />}
          />
          <Route
            path="/interpretation-types/vri/other-interpretation-services"
            element={<Others />}
          />

          {/* sign language route */}
          <Route
            path="/interpretation-types/asl-interpretation"
            element={<HomeASL />}
          />
          <Route
            path="/interpretation-types/asl-interpretation/american-sign-language/"
            element={<ASL />}
          />
          <Route
            path="/interpretation-types/asl-interpretation/english-sign-language/"
            element={<ESL />}
          />
          <Route
            path="/interpretation-types/asl-interpretation/rid-certified-asl-interpreters/"
            element={<RID />}
          />

          <Route
            path="/interpretation-types/consecutive-simultaneous"
            element={<ConsecutiveSimultaneous />}
          />

          {/* quote */}
          <Route path="/free-quote" element={<Quote />} />

          {/* about us & blog */}
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/about-us/our-interpreters" element={<Interpreters />} />
          <Route path="/about-us/certifications" element={<Certifications />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:slug" element={<PostPage />} />
          <Route path="/terms-and-conditions" element={<TC />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          {/* thanks */}
          <Route path="/thanks" element={<Thanks />} />
          <Route path="/thanks-landing" element={<ThanksLanding />} />

          {/* languages route */}
          <Route path="/language-expertise" element={<LanguageExpertise />} />
          <Route
            path="/language-expertise/spanish-interpretation"
            element={<SpanishExpertise />}
          />
          <Route
            path="/language-expertise/english-interpretation"
            element={<EnglishExpertise />}
          />
          <Route
            path="/language-expertise/chinese-interpretation"
            element={<ChineseExpertise />}
          />
          <Route
            path="/language-expertise/french-interpretation"
            element={<FrenchExpertise />}
          />
          <Route
            path="/language-expertise/japanese-interpretation"
            element={<JapaneseExpertise />}
          />
          <Route
            path="/language-expertise/arabic-interpretation"
            element={<ArabicExpertise />}
          />
          <Route
            path="/language-expertise/german-interpretation"
            element={<GermanExpertise />}
          />
          <Route
            path="/language-expertise/portuguese-interpretation"
            element={<PortugueseExpertise />}
          />
          <Route
            path="/language-expertise/italian-interpretation"
            element={<ItalianExpertise />}
          />
          <Route
            path="/language-expertise/haitian-creole-interpretation"
            element={<HaitianExpertise />}
          />

          {/* expertise route */}
          <Route path="/expertise" element={<Expertise />} />
          <Route
            path="/expertise/legal-interpretation"
            element={<LegalInterpretation />}
          />
          <Route
            path="/expertise/medical-interpretation"
            element={<MedicalInterpretation />}
          />
          <Route
            path="/expertise/immigration-interpretation"
            element={<ImmigrationInterpretation />}
          />
          <Route
            path="/expertise/business-interpretation"
            element={<BusinessInterpretation />}
          />
          <Route
            path="/expertise/financial-interpretation"
            element={<FinancialInterpretation />}
          />
          <Route
            path="/expertise/technology-interpretation"
            element={<TechnologyInterpretation />}
          />
          <Route
            path="/expertise/educational-interpretation"
            element={<EducationalInterpretation />}
          />
          <Route
            path="/expertise/government-interpretation"
            element={<GovernmentInterpretation />}
          />

          {/* cities route */}
          <Route path="/interpretation-services/austin" element={<Austin />} />
          <Route path="/interpretation-services/boston" element={<Boston />} />
          <Route path="/interpretation-services/miami" element={<Miami />} />
          <Route
            path="/interpretation-services/charlotte"
            element={<Charlotte />}
          />
          <Route
            path="/interpretation-services/chicago"
            element={<Chicago />}
          />
          <Route
            path="/interpretation-services/columbus"
            element={<Columbus />}
          />
          <Route path="/interpretation-services/dallas" element={<Dallas />} />
          <Route path="/interpretation-services/denver" element={<Denver />} />
          <Route path="/interpretation-services/el-paso" element={<ElPaso />} />
          <Route
            path="/interpretation-services/fort-worth"
            element={<FortWorth />}
          />
          <Route
            path="/interpretation-services/houston"
            element={<Houston />}
          />
          <Route
            path="/interpretation-services/indianapolis"
            element={<Indianapolis />}
          />
          <Route
            path="/interpretation-services/jacksonville"
            element={<Jacksonville />}
          />
          <Route
            path="/interpretation-services/los-angeles"
            element={<LosAngeles />}
          />
          <Route
            path="/interpretation-services/nashville"
            element={<Nashville />}
          />
          <Route
            path="/interpretation-services/new-york"
            element={<NewYork />}
          />
          <Route
            path="/interpretation-services/oklahoma-city"
            element={<OklahomaCity />}
          />
          <Route
            path="/interpretation-services/philadelphia"
            element={<Philadelphia />}
          />
          <Route
            path="/interpretation-services/phoenix"
            element={<Phoenix />}
          />
          <Route
            path="/interpretation-services/portland"
            element={<Portland />}
          />
          <Route
            path="/interpretation-services/san-antonio"
            element={<SanAntonio />}
          />
          <Route
            path="/interpretation-services/san-diego"
            element={<SanDiego />}
          />
          <Route
            path="/interpretation-services/san-francisco"
            element={<SanFrancisco />}
          />
          <Route
            path="/interpretation-services/san-jose"
            element={<SanJose />}
          />
          <Route
            path="/interpretation-services/seattle"
            element={<Seattle />}
          />
          <Route
            path="/interpretation-services/washington"
            element={<Washington />}
          />
          
          {/* 404 not found */}
          <Route path="*" element={<NotFound />} />

          {/* landings */}
          {/* OSI MIAMI */}
          <Route
            path="/landing/on-site-interpretation-services-miami"
            element={<LandingOSIMiami />}
          />
          {/* OSI NYC */}
          <Route
            path="/landing/on-site-interpretation-services-new-york"
            element={<LandingOSINYC />}
          />
          {/* OSI WASHINGTON */}
          <Route
            path="/landing/on-site-interpretation-services-washington"
            element={<LandingOSIWashington />}
          />
          {/* OSI CHICAGO */}
          <Route
            path="/landing/on-site-interpretation-services-chicago"
            element={<LandingOSIChicago />}
          />
          {/* VRI */}
          <Route
            path="/landing/vri"
            element={<LandingVRI />}
          />
          {/* NYC */}
          <Route
            path="/landing/interpretation-services-new-york"
            element={<LandingNYC />}
          />
          {/* Chicago */}
          <Route
            path="/landing/interpretation-services-chicago"
            element={<LandingChicago />}
          />
        </Routes>
      </div>
    </Router>
  );
};

// Componente para manejar useLocation
const LocationHandler: React.FC<{
  setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveMenu: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({ setIsMobileMenuOpen, setActiveMenu }) => {
  const location = useLocation();
  
  useEffect(() => {
    // Cuando cambias de ruta, cierra el menú móvil y los submenús
    setIsMobileMenuOpen(false);
    setActiveMenu(null);
  }, [location, setIsMobileMenuOpen, setActiveMenu]);
  
  return null;
};

export default App;
