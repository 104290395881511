import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import client from '../../services/contentfulClient';
import Footer from '../../components/Footer';

const PostPage: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<any | null>(null);
  const options = {
    renderNode: {
      [BLOCKS.OL_LIST]: (node: any, children: any) => (
        <ol className="list-decimal pl-6">{children}</ol>
      ),
      [BLOCKS.UL_LIST]: (node: any, children: any) => (
        <ul className="list-disc pl-6">{children}</ul>
      ),
      [BLOCKS.PARAGRAPH]: (() => {
        let paragraphCount = 0;
  
        return (node: any, children: any) => {
          const currentIndex = paragraphCount;
          paragraphCount++;
  
          return (
            <>
              <p className="mb-4">{children}</p>
              {currentIndex === 0 && post?.fields.media?.fields?.file?.url && (
                <div className="flex justify-center mb-10 mt-6">
                  <img
                    src={post.fields.media.fields.file.url}
                    alt={post.fields.title || "Blog post image"}
                    className="max-w-full max-h-96 rounded-lg shadow-md"
                  />
                </div>
              )}
            </>
          );
        };
      })(),
      [INLINES.HYPERLINK]: (node: any, children: any) => (
        <a
          href={node.data.uri}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-600 underline hover:text-blue-800"
        >
          {children}
        </a>
      ),
    },
  };
   
  
  
  useEffect(() => {
    client
      .getEntries({
        content_type: 'blogPost',
        'fields.slug': slug,
      })
      .then((response) => {
        if (response.items.length > 0) {
          setPost(response.items[0]);
        }
      })
      .catch(console.error);
  }, [slug]);

  if (!post) {
    return <div className="container mx-auto px-4 py-16">Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-16">
      <h1 className="text-4xl font-bold mb-4">{post.fields.title}</h1>

      <div className="text-gray-800 text-lg leading-relaxed mb-6">
        {documentToReactComponents(post.fields.body, options)}
      </div>

      {/* {post.fields.media?.fields?.file?.url && (
        <div className="flex justify-center mt-6">
          <img
            src={post.fields.media.fields.file.url}
            alt={post.fields.title}
            className="max-w-full max-h-96 rounded-lg shadow-md"
          />
        </div>
      )} */}
    </div>
  );
};

export default PostPage;
