import React from 'react';
import '../styles/Testimonials.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import Testimonial1Image from "../assets/images/testimonials/testimonial1.png";
import Testimonial2Image from "../assets/images/testimonials/testimonial2.png";
import Testimonial3Image from "../assets/images/testimonials/testimonial3.png";
import Testimonial4Image from "../assets/images/testimonials/testimonial4.png";
import 'swiper/css';
import 'swiper/css/pagination';

const testimonials = [
    {
        name: "George B.",
        position: "Product Designer",
        image: Testimonial1Image,
        rating: 5,
        text: "Using their services has been a game-changer for our international projects. The precision and professionalism they bring have made cross-cultural communication seamless."
    },
    {
        name: "Zoë H.",
        position: "Design Lead",
        image: Testimonial3Image,
        rating: 5,
        text: "I can confidently communicate with clients across languages, knowing every detail is accurately conveyed."
    },
    {
        name: "Shalini W.",
        position: "Sales Manager",
        image: Testimonial4Image,
        rating: 5,
        text: "The team has been instrumental in expanding our business reach. They consistently provide reliable and timely interpretations for our global meetings."
    },
    {
        name: "Mohanraj S.",
        position: "CEO",
        image: Testimonial2Image,
        rating: 5,
        text: "This company is a must-have for any business with international clients. Their service has greatly enhanced our client relationships worldwide."
    },
];

const TestimonialsSection: React.FC = () => {
    return (
        <section className="testimonials-section py-12">
            <div className="container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mb-10 md:mb-14 text-center">
                    <h2 className="text-4xl font-bold text-gray-900">This is what Our Customers say about Us</h2>
                </div>

                {/* Desktop Grid */}
                <div className="testimonials-grid">
                    {testimonials.map((testimonial, index) => (
                        <div className="hidden md:block testimonial-card" key={index}>
                            <img className="testimonial-image" src={testimonial.image} alt={testimonial.name} />
                            <h5 className="text-gray-900 text-lg font-semibold">{testimonial.name}</h5>
                            <p className="text-indigo-600">{testimonial.position}</p>
                            <div className="testimonial-rating">
                                {"★".repeat(testimonial.rating)}
                            </div>
                            <p className="testimonial-text">{testimonial.text}</p>
                        </div>
                    ))}
                </div>

                {/* Mobile Carousel */}
                <div className="md:hidden">
                    <Swiper
                        spaceBetween={16}
                        slidesPerView={1}
                        modules={[Pagination]}
                        pagination={{ clickable: true }}
                    >
                        {testimonials.map((testimonial, index) => (
                            <SwiperSlide key={index}>
                                <div className="testimonial-card min-h-96 bg-white">
                                    <img className="testimonial-image" src={testimonial.image} alt={testimonial.name} />
                                    <h5 className="text-gray-900 text-lg font-semibold">{testimonial.name}</h5>
                                    <p className="text-indigo-600">{testimonial.position}</p>
                                    <div className="testimonial-rating">
                                        {"★".repeat(testimonial.rating)}
                                    </div>
                                    <p className="testimonial-text">{testimonial.text}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default TestimonialsSection;
