import React from "react";
import TestimonialsSection from "../../../components/TestimonialsSection";
import PageHeader from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import PricingPlans from "../../../components/PricingPlans";
import QuoteForm from "../../../components/QuoteForm";
import { FaVideo, FaClock } from "react-icons/fa6";
import Helmet from "react-helmet";
// images
import NYCImage1 from "../../../../src/assets/images/adobe/NYC.webp";
import LandingBanner from "../../../components/LandingBanner";
import { FaMapMarkerAlt, FaPhoneAlt, FaGlobe, FaCheck, FaCertificate, FaIndustry } from "react-icons/fa";
import ClientsCarousel from "../../../components/ClientsCarousel";
import SubFooter from "../../../components/SubFooter";

const LandingNYC: React.FC = () => {
  const pricingPlans = [
    {
      icon: FaVideo,
      title: "Video Remote",
      price: 56,
      description: "For large organizations and enterprises",
      benefits: [
        "Real-time remote interpretation",
        "Combines audio and visual clarity",
        "Accessible anywhere online",
        "Wide Availability of Interpreters",
        "Professional Privacy and Security",
      ],
      url: "https://orders.trustedinterpreters.com/iform-quote/KYLPv11681413693?service_type=interpretation&text=remote&landing=2&website_sale_c=www.trustedinterpreters.com",
    },
    {
      icon: FaMapMarkerAlt,
      title: "On-Site",
      price: 99,
      description: "Ideal for individuals and small teams",
      benefits: [
        "Interpretation Quality Assured",
        "Industry-specific expertise",
        "Nationwide availability",
        "Wide Availability of Interpreters",
        "Professional Privacy and Security",
      ],
      url: "https://orders.trustedinterpreters.com/iform-quote/KYLPv11681413693?service_type=interpretation&text=onsite&type_i=onsite_consecutive&landing=2&website_sale_c=www.trustedinterpreters.com",
    },
    {
      icon: FaPhoneAlt,
      title: "Over-The-Phone",
      price: 1.7,
      description: "Perfect for growing businesses",
      benefits: [
        "Fast and reliable interpretation",
        "Available 24/7 on demand",
        "Covers 200+ languages",
        "Wide Availability of Interpreters",
        "Professional Privacy and Security",
      ],
      url: "https://orders.trustedinterpreters.com/iform-quote/KYLPv11681413693?service_type=interpretation&text=overphone&landing=2&website_sale_c=www.trustedinterpreters.com",
    },
  ];

  return (
    <div className="bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <Helmet>
        <title>Interpretation Services New York City</title>
        <meta
          name="description"
          content="Trusted Interpreters offers certified interpretation services in New York for all industries. Call us today!"
        />
      </Helmet>
      <PageHeader
        title="New York Interpretation Services"
        description="Trusted Interpreters provides high-quality on-site interpretation services in New York, specializing in both consecutive and simultaneous interpretation. Our expert linguists, combined with advanced interpreting technology, ensure seamless communication in any setting. Whether you need interpretation for business meetings, medical appointments, legal proceedings, or large conferences in New York, we deliver precise and professional services tailored to your needs."
        imageUrl={NYCImage1}
      />
      <div className="container mx-auto px-8 pt-6">
        <ClientsCarousel/>
      </div>
      <h2 className="text-3xl font-bold text-center text-gray-800 mb-4 mt-2 px-2">Interpretation Services starting from $56</h2>
      <div className="container mx-auto px-4 pb-16">
        <div className="flex flex-col lg:flex-row w-full">
          <div className="w-80 py-8 lg:py-12 mx-auto order-1 lg:order-none">
          <h2 className="text-2xl font-bold mb-4 mt-2">Leading Interpretation Services Company</h2>
          <ul className="pl-0 text-gray-600 gap-3 flex flex-col">
            <li className="flex items-start gap-3">
              <FaGlobe className="text-[#025F99] mt-1 w-[40px]" />
              <span>Trusted by over 10,000 businesses worldwide</span>
            </li>
            <li className="flex items-start gap-3">
              <FaCheck className="text-[#025F99] mt-1 w-[40px]" />
              <span>Backed by 20+ years of industry experience</span>
            </li>
            <li className="flex items-start gap-3">
              <FaVideo className="text-[#025F99] mt-1 w-[40px]" />
              <span>Professional Video Remote Interpretation (VRI) services</span>
            </li>
            <li className="flex items-start gap-3">
              <FaCertificate className="text-[#025F99] mt-1 w-[40px]" />
              <span>Certified interpreters for accurate and reliable communication</span>
            </li>
            <li className="flex items-start gap-3">
              <FaIndustry className="text-[#025F99] mt-1 w-[40px]" />
              <span>Available on-demand across multiple industries and settings</span>
            </li>
          </ul>
          </div>
          <div id="quote-form" className="w-full">
            <QuoteForm />
          </div>
        </div>
        
        <PricingPlans
          title=""
          plans={pricingPlans}
          subtitleClassName="text-3xl font-bold text-center text-gray-800 mb-4 mt-2"
        />
      </div>

      <LandingBanner />

      {/* testimonials */}
      <TestimonialsSection />
      
      <SubFooter />
    </div>
  );
};

export default LandingNYC;
